import React, {useEffect, useState, useRef} from 'react'
import PropTypes from 'prop-types'
import {DPDynamicNumberInput} from '../../common/DPDynamicNumberInput'
import {calculateStateTax, getDynamicNumberValue, parseDynamicNumberValue, roundToTwoDecimals} from '../util/Utils'
import {ReactSVG} from 'react-svg'
import {getValueView} from './BalanceDetail'
import {useDispatch, useSelector} from 'react-redux'
import {setTaxExempt} from '../../actions'
import {pathServer} from '../../../common/Constants'
import {SALES_BALANCE_TOTAL_BOX_LABELS } from '../../Constants'

export const BalanceSummary = (props) => {
  const [tax, setTax] = useState(0)
  const [previouslyEnteredTax, setPreviouslyEnteredTax] = useState(undefined)
  const [isEditingTax, setIsEditingTax] = useState(false)
  const taxInputRef = useRef(null) // use to handle click outside input
  const formatNumber = (x) => {
    const formattedNumber = x * 100 // Convert to percentage
    return formattedNumber % 1 === 0 ? formattedNumber.toFixed(0) : formattedNumber.toFixed(2) // Check if it's a whole number
  }
  useEffect(() => {
    props.setIsTaxExempt(props.saleStateTax !== null
        ? (props.saleStateTax === 0)
        : (props.stateTaxPercentage === null))

    if (props.readOnlySale) {
      setTax(formatNumber(props.saleStateTax))
    } else {
      setTax((formatNumber(props.saleStateTax) !== null)
        ? formatNumber(props.saleStateTax)
        : (formatNumber(props.stateTaxPercentage) === null ? 0 : formatNumber(props.stateTaxPercentage))
      )
    }
  },[])

  function handleChangeValue(modelValue,viewValue,evt){
    evt.stopPropagation()
    const value = getDynamicNumberValue(modelValue, viewValue)
    setTax(value)
    props.setTax(value === "" ? 0 : value/100)
    props.setIsTaxExempt(false)
  }

  function toggleTaxExempt() {
    if (tax !== 0 && !props.isTaxExempt) {
      setPreviouslyEnteredTax(tax)
    }

    const taxRate = !props.isTaxExempt
      ? 0
      : previouslyEnteredTax !== undefined
        ? previouslyEnteredTax
        : props.saleStateTax !== 0
          ? props.saleStateTax * 100
          : props.stateTaxPercentage * 100

    setTax(taxRate)
    props.setTax(taxRate / 100)
    props.setIsTaxExempt(!props.isTaxExempt)
  }

  const {carPrice,accessoryServiceSubtotal,additionalRebateSubtotal,saleTradeInsSubtotal,saleRegistrationFeesSubtotal,
    tradeInAllowance,stateTaxPercentage} = props
  const balanceDue = parseDynamicNumberValue(carPrice) + parseDynamicNumberValue(accessoryServiceSubtotal) - parseDynamicNumberValue(additionalRebateSubtotal) -
    parseDynamicNumberValue(saleTradeInsSubtotal) + parseDynamicNumberValue(saleRegistrationFeesSubtotal)
  const taxValue = calculateStateTax(carPrice, accessoryServiceSubtotal, additionalRebateSubtotal, tradeInAllowance, tax/100)
  const total = roundToTwoDecimals(roundToTwoDecimals(balanceDue) + roundToTwoDecimals(taxValue))

  const handleToggleEdit = () => {
    setIsEditingTax((prevState) => !prevState)
  }
  const handleKeyDown =(e) => {
    if (e.key === 'Enter') {
      setIsEditingTax((prevState) => !prevState)
    }
  }

  const handleBlur = (evt) => {
    evt.stopPropagation()
    const value = evt.target.value ? evt.target.value : '0'
    setTax(value)
  }

  // handle click outside input
  const handleStopPropagation = (e) => {
    e.stopPropagation()
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => document.removeEventListener('click', handleClickOutside, true)
  }, [])

  const handleClickOutside = (event) => {
    if (taxInputRef.current && !taxInputRef.current.contains(event.target)) {
      setIsEditingTax(false)
    }
  }

  // handle enter
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        setIsEditingTax((prevState) => !prevState)
        if (taxInputRef.current) {
          taxInputRef.current.removeEventListener('keydown', handleKeyPress)
        }
      }
    }
    if (isEditingTax){
      taxInputRef.current.addEventListener('keydown', handleKeyPress)
    }
  }, [isEditingTax])

  return (
    <div className="container-balance-summary">
      <div className={'balance-row'}>
        <DPDynamicNumberInput
          className={''}
          classWrapperInput={'input-disabled'}
          title={SALES_BALANCE_TOTAL_BOX_LABELS.PRICE}
          id={'input-price-subtotal'}
          value={getValueView(carPrice)}
          thousand={true}
          integer={8}
          separator={'.'}
          fraction={2}
          placeHolder={'0.00'}
          disabled={true}
          withSymbol={true}
        />
      </div>
      <div className={'balance-row'}>
        <DPDynamicNumberInput
          className={''}
          classWrapperInput={'input-disabled'}
          title={SALES_BALANCE_TOTAL_BOX_LABELS.ACCESSORIES_SERVICES}
          id={'input-accessory-service-subtotal'}
          value={getValueView(accessoryServiceSubtotal)}
          thousand={true}
          integer={8}
          separator={'.'}
          fraction={2}
          placeHolder={'0.00'}
          disabled={true}
          withSymbol={true}
        />
      </div>
      <div className={'balance-row'}>
        <DPDynamicNumberInput
          className={''}
          classWrapperInput={'input-disabled'}
          title={SALES_BALANCE_TOTAL_BOX_LABELS.REBATES}
          id={'input-rebates-subtotal'}
          value={getValueView(additionalRebateSubtotal)}
          thousand={true}
          integer={8}
          separator={'.'}
          fraction={2}
          placeHolder={'0.00'}
          disabled={true}
          withSymbol={true}
          positive={false}
          classWrapperSymbol={'subtract'}
        />
      </div>
      <div className={'balance-row'}>
        <DPDynamicNumberInput
          className={''}
          classWrapperInput={'input-disabled'}
          title={SALES_BALANCE_TOTAL_BOX_LABELS.TRADE_IN_CREDITS}
          id={'input-tradeIn-subtotal'}
          value={getValueView(saleTradeInsSubtotal)}
          thousand={true}
          integer={8}
          separator={'.'}
          fraction={2}
          placeHolder={'0.00'}
          disabled={true}
          withSymbol={true}
          negative={true}
          classWrapperSymbol={'subtract'}
        />
      </div>
      <div className={'balance-row'}>
        <DPDynamicNumberInput
          className={''}
          classWrapperInput={'input-disabled'}
          title={SALES_BALANCE_TOTAL_BOX_LABELS.STATE_TAX}
          id={'input-tax-subtotal'}
          value={getValueView(Math.round(taxValue*100)/100)}
          thousand={true}
          integer={8}
          separator={'.'}
          fraction={2}
          placeHolder={'0.00'}
          disabled={true}
          withSymbol={true}
          negative={true}
        />
        <div>
          {
            isEditingTax
              ?
              <div className="input-container"
                   ref={taxInputRef}
              >
                <DPDynamicNumberInput
                  className={'tax-edit'}
                  classWrapperInput={''}
                  defaultValue={0}
                  value={tax}
                  onChange={(evt, modelValue, viewValue) => handleChangeValue(modelValue,viewValue,evt)}
                  onBlur={handleBlur}
                  canSelectText={false}
                  thousand={true}
                  integer={2}
                  separator={'.'}
                  fraction={2}
                  placeHolder={'0.00'}
                  disabled={false}
                  withSymbol={false}
                  negative={false}
                  maxLength={5}
                  styleInput={{"padding":"0"}}
                />
                <span>%</span>
              </div>
              : <label
                  className="tax-label"
                  onClick={handleToggleEdit}
                >
                  ({`${tax} %`})
                </label>
          }
        </div>
          <div className="exempt-checkbox">
          <input type="checkbox"  name="tax-exempt-check" checked={props.isTaxExempt}
                 onClick={toggleTaxExempt}
                 disabled={props.readOnlySale}
          />
          <label htmlFor="tax-exempt-check" className="exempt-checkbox-label">
            Non taxable
          </label>
        </div>
      </div>
      <div className={'balance-row balance-row-end'}>
        <DPDynamicNumberInput
          className={''}
          classWrapperInput={'input-disabled'}
          title={SALES_BALANCE_TOTAL_BOX_LABELS.REGISTRATION_FEE}
          id={'input-registration-subtotal'}
          value={getValueView(saleRegistrationFeesSubtotal)}
          thousand={true}
          integer={8}
          separator={'.'}
          fraction={2}
          placeHolder={'0.00'}
          disabled={true}
          withSymbol={true}
        />
      </div>
      <div className={'balance-row'}>
        <DPDynamicNumberInput
          className={''}
          classWrapperInput={'input-disabled'}
          title={SALES_BALANCE_TOTAL_BOX_LABELS.BALANCE_DUE}
          id={'input-balance-subtotal'}
          value={getValueView(total)}
          thousand={true}
          integer={8}
          separator={'.'}
          fraction={2}
          placeHolder={'0.00'}
          disabled={true}
          withSymbol={true}
          negative={true}
        />
      </div>
      <div className={'balance-row'}>
        <span className={'label-add-price'}>"$" Add to the price</span>
        <span className={'label-subtract'}>"$" Subtract to the price</span>
      </div>
    </div>
  )
}

BalanceSummary.propTypes = {
  carPrice: PropTypes.number,
  accessoryServiceSubtotal: PropTypes.number,
  additionalRebateSubtotal: PropTypes.number,
  saleTradeInsSubtotal: PropTypes.number,
  saleRegistrationFeesSubtotal: PropTypes.number,
  tradeInAllowance: PropTypes.number,
}