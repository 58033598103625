import React from 'react'
import isEmpty from 'lodash/isEmpty'
import {ReactSVG} from 'react-svg'
import PropTypes from 'prop-types'
import {pathServer} from '../../../../common/Constants'
import {
  SALES_NON_TAXABLE_STATE_INSPECTION_CODE,
  SALES_NON_TAXABLE_TEMPORARY_PERMIT_CODE, SALES_NON_TAXABLE_TITLING_FEE_CODE,
  SALES_REGISTRATION_FEES_CODE
} from '../../../Constants'


class SaleSettingColumnSectionElementForFees extends React.Component {
  render() {
    const { element, index, columnName, handleChangeToEditMode, handleDeleteElement, className, onMouseOver, onMouseOut } = this.props
    const cost = !isEmpty(element.value)
      ? `$${parseFloat(element.value).toFixed(2)}  `
      : 'Not set'

    return (
      <div
        className={className}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <div key={element.id} className={(index % 2 === 0) ? "container-accessory row-color" : "container-accessory"}>
          <div className='element-detailed-info'>
            <div className='element-title'>{element.description}</div>
            <div className='element-price'>{cost}</div>
          </div>

          <div
            id={`element-options-${columnName}-${element.id}`}
            className='element-options'
          >
            <ReactSVG
              src={pathServer.PATH_IMG + 'icon/edit-pencil.svg'}
              beforeInjection={svg => svg.classList.add('edit-option')}
              onClick={handleChangeToEditMode}
            />
            { (element.code === SALES_REGISTRATION_FEES_CODE ||
              element.code === SALES_NON_TAXABLE_STATE_INSPECTION_CODE ||
              element.code === SALES_NON_TAXABLE_TEMPORARY_PERMIT_CODE ||
              element.code === SALES_NON_TAXABLE_TITLING_FEE_CODE) ? null :
              <ReactSVG
                src={pathServer.PATH_IMG + 'icon/waste-can.svg'}
                beforeInjection={svg => svg.classList.add('delete-option')}
                onClick={() => handleDeleteElement()}
              />
            }

          </div>
        </div>
      </div>
    )
  }
}

SaleSettingColumnSectionElementForFees.propTypes = {
  element: PropTypes.object,
  index: PropTypes.number,
  columnName: PropTypes.string,
  handleChangeToEditMode: PropTypes.func,
  handleDeleteElement: PropTypes.func,
  className: PropTypes.string,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
}

export default SaleSettingColumnSectionElementForFees